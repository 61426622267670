import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Text from '@particles/text'
import SEO from '@atoms/seo'
import { graphql } from 'gatsby'
import Contributor from '@molecules/contributor'
import styled from 'styled-components'

const ContributorCard = styled.div`
  padding: 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
`
const ContributorsPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Contributors" location={location} />
    <Heading h1 margin="T2 B2.5">
      Contributors
    </Heading>
    <Text size={1.25}>
      MetricHQ is supported by amazing domain experts who share their experience
      and knowledge to help all business leaders and decision-makers succeed in
      today&apos;s data-driven world.
    </Text>
    <Text size={1.25} margin="T2">
      Here is the growing team who is making it happen. Meet the experts behind
      MetricHQ!
    </Text>
    <Layout.Grid full min={350} margin="T2" gap={1}>
      {data.contributors.nodes.map((contributor, i) => (
        <ContributorCard key={`contribute-${i}`}>
          <Contributor
            name={contributor.name}
            subtitle={contributor.subtitle}
            twitter={contributor.twitter}
            linkedin={contributor.linkedin}
            web={`/contributor/${contributor.name
              .toLowerCase()
              .replace(/ /g, '_')}`}
            imageFile={contributor.image}
            imageAlt={contributor.image?.title ?? 'Contributor Image'}
          />
        </ContributorCard>
      ))}
    </Layout.Grid>
  </Layout>
)

ContributorsPage.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}

export default ContributorsPage

export const pageQuery = graphql`
  query ContributorsPageQuery {
    contributors: allContributor {
      nodes {
        subtitle
        name
        image {
          cdn
          placeholder
        }
        linkedin
        twitter
      }
    }
  }
`
