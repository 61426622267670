/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
// Description cutoff at 150-160
function SEO({ description, lang, meta, title, location, override }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  )
  // let myUrl = "";
  // if(site.url) {
  //   let pathname = location.pathname || '/';
  //   if(pathname.endsWith('/')) {
  //     pathname = pathname.substring(0, pathname.length - 1);
  //   }

  //   myUrl = `${site.url}${site.url.endsWith('/') ? '' : '/'}${pathname}`
  // }

  const metaDescription = description || site.siteMetadata.description
  let canonicalUrl;
  try {
    const locationURL = new URL(location.href);
    canonicalUrl = `${locationURL.origin}${locationURL.pathname}`;
  } catch (e) {
    canonicalUrl = location.href;
  }
  


  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      link={[
        {
          rel: 'canonical',
          key: canonicalUrl,
          href: canonicalUrl
        }
      ]}
      title={title}
      titleTemplate={override ? '%s' : `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'referrer',
          content: 'no-referrer-when-downgrade'
        },
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: canonicalUrl
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string
  }).isRequired,
  override: PropTypes.bool
}

export default SEO
